<template>
  <div class="my-3 event-table">
    <b-button :disabled="!isAdmin" @click="eventCreating = !eventCreating" variant="primary">Создать событие</b-button>
    <b-modal v-model="eventCreating" hide-footer hide-header>
      <event-creation @cancel="eventCreating = false" @submit="createEvent"/>
    </b-modal>
    <b-table
        hover
        ref="eventListTable"
        :items="getAllEvents"
        :fields="eventTableFields"
        class="table-bordered table-hover my-3"
        show-empty>
      <template #table-busy>
        <loading-spinner/>
      </template>
      <template v-slot:empty="scope">
        <h6 class="text-center">События отсутствуют</h6>
      </template>
      <template v-slot:cell(description)="row">
        <b-form-input required :readonly="!isAdmin" v-model="row.item.description"/>
      </template>
      <template v-slot:cell(value)="row">
        <b-form-input type="number" required :readonly="!isAdmin" v-model.number="row.item.value"/>
      </template>
      <template v-slot:cell(isAbsoluteValue)="row">
        <b-form-checkbox required :disabled="!isAdmin" v-model="row.item.isAbsoluteValue"/>
      </template>
      <template v-slot:cell(isStoppingRecommendation)="row">
        <b-form-checkbox required :disabled="!isAdmin" v-model="row.item.isStoppingRecommendation"/>
      </template>
      <template v-slot:cell(action)="row">
        <div class="d-flex justify-content-between">
          <b-button size="sm" variant="success"
                    :disabled="!isAdmin"
                    title="Сохранить"
                    @click="updateEvent(row.item)">
            <b-icon-check/>
          </b-button>
          <b-button size="sm" variant="danger"
                    :disabled="!isAdmin"
                    title="Удалить"
                    @click="deleteEvent(row.item.name)">
            <b-icon-trash/>
          </b-button>
        </div>
      </template>
    </b-table>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import userInteraction from "@/modules/user-interaction";
import LoadingSpinner from "@/components/LoadingSpinner.vue";
import EventCreation from "@/components/EventCreation.vue";

export default {
  name: "event-list",
  components: {
    'loading-spinner': LoadingSpinner,
    'event-creation': EventCreation
  },
  data() {
    return {
      eventCreating: false,
      eventTableFields: [
        {
          key: 'name', label: 'Название'
        },
        {
          key: 'description', label: 'Описание', thStyle: { width: "25%" }
        },
        {
          key: 'value', label: 'Вес'
        },
        {
          key: 'isAbsoluteValue', label: 'Абсолютное значение'
        },
        {
          key: 'isStoppingRecommendation', label: 'Останавливает рекомендацию'
        },
        {
          key: 'action', label: 'Действия'
        }
      ],
    }
  },
  created() {
    document.title = this.$route.meta.title;
  },
  computed: {
    ...mapGetters([
      "isRolesEmpty",
      "isAdmin"
    ])
  },
  methods: {
    getAllEvents() {
      return userInteraction.getAllEvents()
          .then(response => response.data)
          .catch(error => {
            if (error.response != null) {
              if (error.response.status === 403) {
                this.errorMessage = "Недостаточно прав для просмотра событий";
              } else if (error.response.status === 429) {
                this.errorMessage = "Слишком много запросов";
              } else if (error.response.status === 401) {
                this.errorMessage = "Сессия истекла. Пожалуйста, перезайдите в аккаунт";
              } else {
                this.errorMessage = "Не удалось загрузить события";
              }
            } else {
              this.errorMessage = "Не удалось загрузить события";
            }
            this.$bvToast.toast(this.errorMessage + (error.response == null ? '' : ` (код ${error.response.status})`), {
              title: "Ошибка",
              variant: "danger",
              autoHideDelay: 5000,
              appendToast: true
            });
          });
    },
    updateEvent(event) {
      if (event.value == null || event.value.length === 0) {
        event.value = 0;
      }

      userInteraction.updateEvent(event)
          .then(() => {
            this.$bvToast.toast('Событие успешно обновлено', {
              variant: 'success',
              solid: true,
              noCloseButton: true
            });
          })
          .catch(error => {
            if (error.response != null) {
              if (error.response.status === 403) {
                this.errorMessage = "Недостаточно прав для обновления событий";
              } else if (error.response.status === 429) {
                this.errorMessage = "Слишком много запросов";
              } else if (error.response.status === 401) {
                this.errorMessage = "Сессия истекла. Пожалуйста, перезайдите в аккаунт";
              } else if (error.response.status === 404) {
                this.errorMessage = `Событие ${event.name} не найдено`;
              } else {
                this.errorMessage = "Не удалось обновить событие";
              }
            } else {
              this.errorMessage = "Не удалось обновить событие";
            }
            this.$bvToast.toast(this.errorMessage + (error.response == null ? '' : ` (код ${error.response.status})`), {
              title: "Ошибка",
              variant: "danger",
              autoHideDelay: 5000,
              appendToast: true
            });
          });
    },
    createEvent(event) {
      userInteraction.createEvent(event)
          .then(() => {
            this.eventCreating = false;
            this.$refs.eventListTable.refresh();
            this.$bvToast.toast('Событие успешно создано', {
              variant: 'success',
              solid: true,
              noCloseButton: true
            });
          })
          .catch(error => {
            if (error.response != null) {
              if (error.response.status === 403) {
                this.errorMessage = "Недостаточно прав для создания событий";
              } else if (error.response.status === 429) {
                this.errorMessage = "Слишком много запросов";
              } else if (error.response.status === 401) {
                this.errorMessage = "Сессия истекла. Пожалуйста, перезайдите в аккаунт";
              } else if (error.response.status === 409) {
                this.errorMessage = `Событие ${event.name} уже существует`;
              } else {
                this.errorMessage = "Не удалось создать событие";
              }
            } else {
              this.errorMessage = "Не удалось создать событие";
            }
            this.$bvToast.toast(this.errorMessage + (error.response == null ? '' : ` (код ${error.response.status})`), {
              title: "Ошибка",
              variant: "danger",
              autoHideDelay: 5000,
              appendToast: true
            });
          });
    },
    deleteEvent(eventName) {
      this.$bvModal
          .msgBoxConfirm('Вы уверены, что хотите удалить событие?', {
            title: 'Удаление',
            titleTag: 'h6',
            okVariant: 'danger',
            okTitle: 'Удалить',
            cancelTitle: 'Отмена',
            cancelVariant: 'outline-secondary',
            footerClass: 'p-2',
            hideHeaderClose: false,
            centered: true
          })
          .then(value => {
            if (value) {
              userInteraction.deleteEvent(eventName)
                  .then(() => {
                    this.$refs.eventListTable.refresh();
                    this.$bvToast.toast('Событие успешно удалено', {
                      variant: 'success',
                      solid: true,
                      noCloseButton: true
                    });
                  })
                  .catch(error => {
                    if (error.response != null) {
                      if (error.response.status === 403) {
                        this.errorMessage = "Недостаточно прав для удаления событий";
                      } else if (error.response.status === 429) {
                        this.errorMessage = "Слишком много запросов";
                      } else if (error.response.status === 401) {
                        this.errorMessage = "Сессия истекла. Пожалуйста, перезайдите в аккаунт";
                      } else if (error.response.status === 404) {
                        this.errorMessage = `Событие ${eventName} не найдено`;
                      } else {
                        this.errorMessage = "Не удалось удалить событие";
                      }
                    } else {
                      this.errorMessage = "Не удалось удалить событие";
                    }
                    this.$bvToast.toast(this.errorMessage + (error.response == null ? '' : ` (код ${error.response.status})`), {
                      title: "Ошибка",
                      variant: "danger",
                      autoHideDelay: 5000,
                      appendToast: true
                    });
                  });
            }
          })
    }
  }
}
</script>

<style scoped>
@media (min-width: 1280px) {
  .event-table {
    width: 70%;
    margin-right: auto;
    margin-left: auto;
  }
}

@media (min-width: 960px) and (max-width: 1279px) {
  .event-table {
    width: 90%;
    margin-right: auto;
    margin-left: auto;
  }
}

@media (max-width: 959px) {
  .event-table {
    width: 99%;
    margin-right: auto;
    margin-left: auto;
  }
}
</style>