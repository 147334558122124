export default {
    isFloat(value) {
        if (typeof value == "number" && isFinite(value)) {
            return true;
        }
        if (typeof value != "string") {
            return false;
        }
        return !isNaN(value) && !isNaN(parseFloat(value));
    },
    isInt(value) {
        return /^[-+]?\d+$/.test(value);
    }
}