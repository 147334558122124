<template>
    <b-container class="pt-2">
        <div class="mt-3">
            В процессе обучения модели и формирования рекомендаций:
            <span class="bold-font" :class="isTraining == null ? 'failed-status' : (isTraining ? 'in-progress-status' : 'not-in-progress-status')">
                {{ isTraining == null ? 'ошибка получения статуса' : (isTraining ? 'ДА' : 'НЕТ') }}
            </span>
        </div>
        <div class="mt-3">
            <b-button :disabled="isTraining"
                      variant="danger"
                      @click="retrainModel">
                Заново обучить модель и сформировать рекомендации
            </b-button>
        </div>
        <b-form-group class="mt-5" label="Установка товарам вероятностей появления в рекомендациях">
            <b-button @click="recommendationCreating = !recommendationCreating" variant="primary">Создать рекомендацию</b-button>
            <b-modal v-model="recommendationCreating" hide-footer hide-header>
                <recommendation-creation @cancel="recommendationCreating = false" @submit="saveRecommendationPossibility"/>
            </b-modal>
            <b-table
                hover
                ref="recommendationTable"
                :items="getRecommendationsPossibilities"
                :fields="tableFields"
                class="table-bordered table-hover my-3"
                show-empty>
                <template #table-busy>
                    <loading-spinner/>
                </template>
                <template v-slot:empty="scope">
                    <h6 class="text-center">Нет заданных рекомендаций</h6>
                </template>
                <template v-slot:cell(recommendationPossibility)="row">
                    <b-form-input :state="isPossibility(row.item.recommendationPossibility)" required
                                  :disabled="!isAdmin" v-model="row.item.recommendationPossibility"/>
                    <div v-if="!isPossibility(row.item.recommendationPossibility)" class="invalid-feedback">
                        Значение должно быть от 0 до 1
                    </div>
                </template>
                <template v-slot:cell(action)="row">
                    <div class="mt-1 d-flex justify-content-between">
                        <b-button size="sm" variant="success"
                                  :disabled="!isAdmin || !isPossibility(row.item.recommendationPossibility)"
                                  title="Сохранить"
                                  @click="saveRecommendationPossibility(row.item.productCode, row.item.recommendationPossibility)">
                            <b-icon-check/>
                        </b-button>
                        <b-button size="sm" variant="danger"
                                  :disabled="!isAdmin"
                                  title="Удалить"
                                  @click="deleteRecommendationPossibility(row.item.productCode)">
                            <b-icon-trash/>
                        </b-button>
                    </div>
                </template>
            </b-table>
        </b-form-group>
    </b-container>
</template>

<script>
import productRecommendation from "@/modules/product-recommendation";
import LoadingSpinner from "@/components/LoadingSpinner.vue";
import {mapGetters} from "vuex";
import configServer from "@/modules/config-server";
import RecommendationCreation from "@/components/RecommendationCreation.vue";
import validations from "@/modules/validation-utils";
import catalogServer from "@/modules/catalog-server";

export default {
    name: "product-recommendation",
    components: {
        'recommendation-creation': RecommendationCreation,
        'loading-spinner': LoadingSpinner
    },
    computed: {
        ...mapGetters([
            "isRolesEmpty",
            "isAdmin"
        ])
    },
    created() {
        this.refreshTrainingStatus();
        document.title = this.$route.meta.title;
    },
    data() {
        return {
            isTraining: false,
            refreshTrainingStatus: () => {
                this.getTrainingStatus();
                setTimeout(() => this.refreshTrainingStatus(), 5000);
            },
            recommendationPossibilities: {},
            recommendationCreating: false,
            tableFields: [
                {
                    key: 'productCode', label: 'Код товара'
                },
                {
                  key: 'name', label: 'Название'
                },
                {
                  key: 'price', label: 'Цена (руб.)'
                },
                {
                    key: 'recommendationPossibility', label: 'Вероятность появления в рекомендациях'
                },
                {
                    key: 'action', label: 'Действия', thStyle: { width: "105px" }
                }
            ]
        };
    },
    beforeDestroy() {
        this.refreshTrainingStatus = () => {};
    },
    methods: {
        isPossibility(value) {
            return validations.isFloat(value) && value >= 0 && value <= 1;
        },
        deleteRecommendationPossibility(productCode) {
            this.$bvModal
                .msgBoxConfirm('Вы уверены, что хотите удалить рекомендацию?', {
                    title: 'Удаление',
                    titleTag: 'h6',
                    okVariant: 'danger',
                    okTitle: 'Удалить',
                    cancelTitle: 'Отмена',
                    cancelVariant: 'outline-secondary',
                    footerClass: 'p-2',
                    hideHeaderClose: false,
                    centered: true
                })
                .then((value) => {
                    if (value) {
                        delete this.recommendationPossibilities[productCode];
                        configServer.updateConfig({
                            'product.possibilities': JSON.stringify(this.recommendationPossibilities)
                        }).then(() => {
                            this.$bvToast.toast('Рекомендация успешно удалена', {
                                variant: 'success',
                                solid: true,
                                noCloseButton: true
                            });
                            this.$refs.recommendationTable.refresh();
                        }).catch(error => {
                            if (error.response != null) {
                                if (error.response.status === 403) {
                                    this.errorMessage = "Недостаточно прав для удаления рекомендаций";
                                } else if (error.response.status === 429) {
                                    this.errorMessage = "Слишком много запросов";
                                } else if (error.response.status === 401) {
                                    this.errorMessage = "Сессия истекла. Пожалуйста, перезайдите в аккаунт";
                                } else {
                                    this.errorMessage = "Не удалось удалить рекомендацию";
                                }
                            } else {
                                this.errorMessage = "Не удалось удалить рекомендацию";
                            }
                            this.$bvToast.toast(this.errorMessage + (error.response == null ? '' : ` (код ${error.response.status})`), {
                                title: "Ошибка",
                                variant: "danger",
                                autoHideDelay: 5000,
                                appendToast: true
                            });
                        });
                    }
                })
        },
        async saveRecommendationPossibility(productCode, possibility, isCreating = false) {
            if (isCreating) {
                if (this.recommendationPossibilities != null && this.recommendationPossibilities[productCode] != null) {
                    this.$bvToast.toast(`Рекомендация с кодом ${productCode} товара уже существует`, {
                        title: "Ошибка",
                        variant: "danger",
                        autoHideDelay: 5000,
                        appendToast: true
                    });
                    return;
                } else {
                    let products;
                    try {
                        products = (await catalogServer.getProductsByCodes([productCode])).data;
                    } catch (error) {
                        this.$bvToast.toast(`Ошибка при обращении к каталогу (код ${error.response?.status})`, {
                            title: "Ошибка",
                            variant: "danger",
                            autoHideDelay: 5000,
                            appendToast: true
                        });
                        return;
                    }
                    if (products == null || products.length === 0) {
                        this.$bvToast.toast(`Товар с кодом ${productCode} не существует`, {
                            title: "Ошибка",
                            variant: "danger",
                            autoHideDelay: 5000,
                            appendToast: true
                        });
                        return;
                    }
                }
            }

            this.recommendationPossibilities[productCode] = Number.parseFloat(possibility);
            configServer.updateConfig({
                'product.possibilities': JSON.stringify(this.recommendationPossibilities)
            }).then(() => {
                this.$bvToast.toast('Рекомендация успешно сохранена', {
                    variant: 'success',
                    solid: true,
                    noCloseButton: true
                });
                this.recommendationCreating = false;
                this.$refs.recommendationTable.refresh();
            }).catch(error => {
                if (error.response != null) {
                    if (error.response.status === 403) {
                        this.errorMessage = "Недостаточно прав для задания рекомендаций";
                    } else if (error.response.status === 429) {
                        this.errorMessage = "Слишком много запросов";
                    } else if (error.response.status === 401) {
                        this.errorMessage = "Сессия истекла. Пожалуйста, перезайдите в аккаунт";
                    } else {
                        this.errorMessage = "Не удалось создать рекомендацию";
                    }
                } else {
                    this.errorMessage = "Не удалось создать рекомендацию";
                }
                this.$bvToast.toast(this.errorMessage + (error.response == null ? '' : ` (код ${error.response.status})`), {
                    title: "Ошибка",
                    variant: "danger",
                    autoHideDelay: 5000,
                    appendToast: true
                });
            });
        },
        getRecommendationsPossibilities() {
            return configServer.getConfig()
                .then(response => {
                    this.recommendationPossibilities = JSON.parse(
                        response.data.propertySources?.find(source => source.name === "redis:recommendation")
                            .source['product.possibilities'] ?? '{}'
                    );
                    const recommendationPossibilities = Object.entries(this.recommendationPossibilities)
                        .map(entry => {
                          return {
                            'productCode': entry[0],
                            'recommendationPossibility': entry[1],
                            'name': null,
                            'price': null
                          };
                        });
                    return catalogServer.getProductsByCodes(recommendationPossibilities.map(possibility => possibility['productCode']))
                        .then(response => {
                          const responseData = response.data;
                          return recommendationPossibilities.map(possibility => {
                            const foundProduct = responseData.find(product => product.productCode === possibility['productCode']);
                            return {
                              'productCode': possibility['productCode'],
                              'recommendationPossibility': possibility['recommendationPossibility'],
                              'name': foundProduct?.name,
                              'price': foundProduct?.price
                            };
                          });
                        })
                        .catch(error => {
                          console.error("Не удалось получить информацию о товарах", error);
                          return recommendationPossibilities;
                        })
                })
                .catch(error => {
                    if (error.response != null) {
                        if (error.response.status === 403) {
                            this.errorMessage = "Недостаточно прав для задания рекомендоваций";
                        } else if (error.response.status === 429) {
                            this.errorMessage = "Слишком много запросов";
                        } else if (error.response.status === 401) {
                            this.errorMessage = "Сессия истекла. Пожалуйста, перезайдите в аккаунт";
                        } else {
                            this.errorMessage = "Не удалось загрузить заданные рекомендации";
                        }
                    } else {
                        this.errorMessage = "Не удалось загрузить заданные рекомендации";
                    }
                    this.$bvToast.toast(this.errorMessage + (error.response == null ? '' : ` (код ${error.response.status})`), {
                        title: "Ошибка",
                        variant: "danger",
                        autoHideDelay: 5000,
                        appendToast: true
                    });
                });
        },
        getTrainingStatus() {
            return productRecommendation.getTrainingStatus()
                .then(response => this.isTraining = response.data['is_training_in_progress'])
                .catch(error => {
                    this.isTraining = null;
                    console.error('Ошибка получения статуса обучения модели и формирования рекомендаций', error);
                });
        },
        retrainModel() {
            this.$bvModal
                .msgBoxConfirm('Вы уверены, что хотите переобучить модель? Старая модель будет удалена', {
                    title: 'Переобучение',
                    titleTag: 'h6',
                    okVariant: 'danger',
                    okTitle: 'Переобучить',
                    cancelTitle: 'Отмена',
                    cancelVariant: 'outline-secondary',
                    footerClass: 'p-2',
                    hideHeaderClose: false,
                    centered: true
                })
                .then(value => {
                    if (value) {
                        return productRecommendation.retrainModel()
                            .then(() => {
                                this.isTraining = true;
                                this.$bvToast.toast('Процесс обучения модели и формирования рекомендаций успешно запущен', {
                                    variant: 'success',
                                    solid: true,
                                    noCloseButton: true
                                });
                            })
                            .catch(error => {
                                if (error.response != null) {
                                    if (error.response.status === 403) {
                                        this.errorMessage = "Недостаточно прав для переобучения модели";
                                    } else if (error.response.status === 429) {
                                        this.errorMessage = "Слишком много запросов";
                                    } else if (error.response.status === 401) {
                                        this.errorMessage = "Сессия истекла. Пожалуйста, перезайдите в аккаунт";
                                    } else if (error.response.status === 503) {
                                        this.isTraining = true;
                                        this.errorMessage = "Обучение модели и формирование рекомендаций уже в процессе";
                                    } else {
                                        this.errorMessage = "Не удалось запустить переобучение модели";
                                    }
                                } else {
                                    this.errorMessage = "Не удалось запустить переобучение модели";
                                }
                                this.$bvToast.toast(this.errorMessage + (error.response == null ? '' : ` (код ${error.response.status})`), {
                                    title: "Ошибка",
                                    variant: "danger",
                                    autoHideDelay: 5000,
                                    appendToast: true
                                });
                            });
                    }
                });
        }
    }
}
</script>

<style scoped>
.bold-font {
    font-weight: bold;
}

.failed-status {
    color: red;
}

.in-progress-status {
    color: green;
}

.not-in-progress-status {
    color: darkorange;
}
</style>