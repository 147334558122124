import Vue from 'vue';
import Router from 'vue-router';
import Login from '@/views/Login';
import store from "@/store";
import Settings from "@/views/Settings.vue";
import EventList from "@/views/EventList.vue";
import ProductRecommendation from "@/views/ProductRecommendation.vue";

Vue.use(Router);

const router = new Router({
    mode: 'history',
    base: '/',
    routes: [
        {path: '/', redirect: '/settings'},
        {path: '/login', component: Login, meta: {title: 'Вход'}},
        {path: '/settings', component: Settings, meta: {title: 'Настройки'}},
        {path: '/events', component: EventList, meta: {title: 'События'}},
        {path: '/product-recommendation', component: ProductRecommendation, meta: {title: 'Формирование рекомендаций'}}
    ]
});

router.beforeEach(async (to, from, next) => {
    if (store.getters.isAuthorized == null) {
        await store.dispatch("refreshToken")
            .catch(error => {
                if (error.response?.status !== 401) {
                    console.error("Ошибка обновления токена", error);
                }
            });
    }
    const directingPath = to.path.endsWith('/') ? to.path.slice(0, -1) : to.path;

    if (!store.getters.isAuthorized && '/login' !== directingPath) {
        return next('/login');
    }
    if (store.getters.isAuthorized && '/login' === directingPath) {
        return next('/settings');
    }
    if (store.getters.isAuthorized && store.getters.isRolesEmpty && '/settings' !== directingPath) {
        return next('/settings');
    }
    return next();
});

export default router;
