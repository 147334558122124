import {USER_INTERACTION_AXIOS} from "@/modules/backend-config";

export default {
    getAllEvents() {
        return USER_INTERACTION_AXIOS.get('/event/all');
    },
    updateEvent(event) {
        return USER_INTERACTION_AXIOS.put('/event', event, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    },
    createEvent(event) {
        return USER_INTERACTION_AXIOS.post('/event', event, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    },
    deleteEvent(eventName) {
        return USER_INTERACTION_AXIOS.delete(`/event/${eventName}`);
    }
}