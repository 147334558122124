<template>
    <div>
        <b-navbar v toggleable="lg" variant="primary">
            <b-navbar-nav>
                <template v-if="!isAuthorized">
                    <b-nav-item v-bind:to="'/login'">Вход</b-nav-item>
                </template>
                <template v-else>
                    <b-nav-item v-if="!isRolesEmpty" v-bind:to="'/settings'">Настройки</b-nav-item>
                    <b-nav-item v-if="!isRolesEmpty" v-bind:to="'/events'">События</b-nav-item>
                    <b-nav-item v-if="!isRolesEmpty" v-bind:to="'/product-recommendation'">Формирование рекомендаций
                    </b-nav-item>
                    <b-nav-item @click="logoutClicked">Выход</b-nav-item>
                </template>
            </b-navbar-nav>
        </b-navbar>
        <router-view></router-view>
    </div>
</template>
<script>
import {mapActions, mapGetters} from "vuex";

export default {
    name: "app",
    mounted() {
        document.title = "Настройки";
    },
    computed: {
        ...mapGetters([
            "isAuthorized",
            "isRolesEmpty"
        ])
    },
    methods: {
        ...mapActions(["logout"]),
        logoutClicked() {
            this.logout()
                .finally(() => {
                    this.$router.push("/login");
                });
        }
    }
};
</script>
<style scoped>
.nav-item.nav-item.nav-item a {
    color: white;
}
</style>
