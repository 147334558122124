import {CONFIG_AXIOS} from "@/modules/backend-config";

export default {
    getConfig() {
        return CONFIG_AXIOS.get('/recommendation/default');
    },
    updateConfig(params) {
        return CONFIG_AXIOS.put('/recommendation', params, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
    }
}