<template>
  <div>
    <h4 class="text-center">Создание рекомендации</h4>
    <b-form @submit.stop.prevent="onSubmit">
      <b-form-group label="Найти товар">
        <b-form-input placeholder="Поиск..." @input="onProductSearch"/>
        <b-form-select class="mt-2" v-model="selectedProduct" :options="foundProducts"/>
      </b-form-group>
      <b-form-group class="mt-3" label="Код товара">
        <b-form-input v-model="selectedProduct" required/>
      </b-form-group>
      <b-form-group label="Вероятность появления в рекомендациях">
        <b-form-input v-model="$v.possibility.$model"
                      aria-describedby="possibilityValidation"
                      :state="$v.possibility.$dirty ? !$v.possibility.$error : null"
                      required/>
        <b-form-invalid-feedback id="possibilityValidation">
          Значение должно быть от 0 до 1
        </b-form-invalid-feedback>
      </b-form-group>
      <div class="text-right mt-3">
        <b-button class="mr-2" variant="secondary" @click="onCancel">
          Отмена
        </b-button>
        <b-button variant="primary" type="submit">
          Создать
        </b-button>
      </div>
    </b-form>
  </div>
</template>

<script>
import {required} from "vuelidate/lib/validators";
import validations from "@/modules/validation-utils";
import catalogServer from "@/modules/catalog-server";

export default {
  name: "event-creation",
  data() {
    return {
      possibility: null,
      selectedProduct: null,
      foundProducts: []
    };
  },
  validations() {
    return {
      selectedProduct: {
        required
      },
      possibility: {
        required,
        possibilityValue: (value) => validations.isFloat(value) && value >= 0 && value <= 1
      }
    }
  },
  methods: {
    onProductSearch(input) {
      catalogServer.searchForProducts(input, 1, 20, `ts_rank(pv.product_text, plainto_tsquery('${input}'))`, 'DESC')
          .then(response => {
            this.foundProducts = response.data
                .map(product => {
                  return {
                    value: product.productCode,
                    text: `${product.name} - ${product.price} руб.`
                  };
                });
            if (this.foundProducts != null && this.foundProducts.length !== 0) {
              this.selectedProduct = this.foundProducts[0].value;
            }
          })
          .catch(error => {
            console.log("Ошибка при поиске товара", error);
            this.$bvToast.toast("Ошибка при поиске товара", {
              title: "Ошибка",
              variant: "danger",
              autoHideDelay: 5000,
              appendToast: true
            });
          })
    },
    onSubmit() {
      if (this.$v.$anyError) {
        return;
      }
      this.$emit('submit', this.selectedProduct, this.possibility, true);
    },
    onCancel() {
      this.$emit('cancel');
    }
  }
}
</script>