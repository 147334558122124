<template>
  <b-container class="pt-2">
    <div class="my-4">
      <div v-if="isRolesEmpty">
        <h1>У вас нет доступа к данному сервису</h1>
      </div>
      <loading-spinner v-else-if="loading"/>
      <b-form v-else @submit.stop.prevent="onSubmit">
        <b-form-group label="Минимальное значение оценки пользователя товару">
          <b-form-input v-model="$v.rating.min.value.$model" :readonly="!isAdmin"
                        required
                        :state="$v.rating.min.value.$dirty ? !$v.rating.min.value.$error : null"
                        aria-describedby="ratingMinValidation"/>
          <b-form-invalid-feedback id="ratingMinValidation">
            Значение должно быть неотрицательным и меньше максимального значения оценки пользователя товару
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group label="Максимальное значение оценки пользователя товару">
          <b-form-input v-model="$v.rating.max.value.$model" :readonly="!isAdmin"
                        required
                        :state="$v.rating.max.value.$dirty ? !$v.rating.max.value.$error : null"
                        aria-describedby="ratingMaxValidation"/>
          <b-form-invalid-feedback id="ratingMaxValidation">
            Значение должно быть больше 0 и больше минимального значения оценки пользователя товару
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group
            :label="`Параметр 'a' функции 'exp(-a * (r-${(Number(rating.max.value) + Number(rating.min.value)) / 2})^2)' влияния события на текущий рейтинг`">
          <b-form-input v-model="$v.rating.function.coefficient.value.$model" :readonly="!isAdmin"
                        required
                        aria-describedby="ratingFunctionCoefficientValidation"
                        :state="$v.rating.function.coefficient.value.$dirty ? !$v.rating.function.coefficient.value.$error : null"/>
          <b-form-invalid-feedback id="ratingFunctionCoefficientValidation">
            Необходимо число
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group
            label="Параметр 'a' функции 'a - exp(-b * t^2)' влияния проведённого на странице товара времени на рейтинг">
          <b-form-input v-model="$v.rating.function.timeSpent.max.value.$model" :readonly="!isAdmin"
                        required
                        aria-describedby="ratingFunctionTimeSpentMaxValidation"
                        :state="$v.rating.function.timeSpent.max.value.$dirty ? !$v.rating.function.timeSpent.max.value.$error : null"/>
          <b-form-invalid-feedback id="ratingFunctionTimeSpentMaxValidation">
            Необходимо число
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group
            label="Параметр 'b' функции 'a - exp(-b * t^2)' влияния проведённого на странице товара времени на рейтинг">
          <b-form-input v-model="$v.rating.function.timeSpent.degreeMultiplier.value.$model" :readonly="!isAdmin"
                        required
                        aria-describedby="ratingFunctionTimeSpentDegreeMultiplierValidation"
                        :state="$v.rating.function.timeSpent.degreeMultiplier.value.$dirty ? !$v.rating.function.timeSpent.degreeMultiplier.value.$error : null"/>
          <b-form-invalid-feedback id="ratingFunctionTimeSpentDegreeMultiplierValidation">
            Необходимо число
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group label="Нижний уровень средей цены">
          <b-form-input v-model="$v.priceLevel.medium.value.$model" :readonly="!isAdmin"
                        required
                        aria-describedby="priceLevelMediumValidation"
                        :state="$v.priceLevel.medium.value.$dirty ? !$v.priceLevel.medium.value.$error : null"/>
          <b-form-invalid-feedback id="priceLevelMediumValidation">
            Значение должно быть положительным и меньше нижнего уровня высокой цены
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group label="Нижний уровень высокой цены">
          <b-form-input v-model="$v.priceLevel.high.value.$model" :readonly="!isAdmin"
                        required
                        aria-describedby="priceLevelHighValidation"
                        :state="$v.priceLevel.high.value.$dirty ? !$v.priceLevel.high.value.$error : null"/>
          <b-form-invalid-feedback id="priceLevelHighValidation">
            Значение должно быть положительным и больше нижнего уровня средней цены
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group label="Рейтинг пользователя товару уменьшается, если между ними не было взаимодействий более стольки минут">
          <b-form-input v-model="$v.rating.noInteraction.time.value.$model" :readonly="!isAdmin"
                        required
                        aria-describedby="noInteractionTimeValidation"
                        :state="$v.rating.noInteraction.time.value.$dirty ? !$v.rating.noInteraction.time.value.$error : null"/>
          <b-form-invalid-feedback id="noInteractionTimeValidation">
            Значение должно быть целым положительным числом
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group label="Величина уменьшения рейтинга при отсутствии взаимодействий между пользователем и товаром">
          <b-form-input v-model="$v.rating.noInteraction.ratingSubtraction.value.$model" :readonly="!isAdmin"
                        required
                        aria-describedby="priceLevelHighValidation"
                        :state="$v.rating.noInteraction.ratingSubtraction.value.$dirty ? !$v.rating.noInteraction.ratingSubtraction.value.$error : null"/>
          <b-form-invalid-feedback id="priceLevelHighValidation">
            Значение должно быть положительным и меньше 100
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group label="Интервал (в минутах) пересчёта рекомендаций для пользователей">
          <b-form-input v-model="$v.formation.interval.value.$model" :readonly="!isAdmin"
                        required
                        aria-describedby="formationIntervalValidation"
                        :state="$v.formation.interval.value.$dirty ? !$v.formation.interval.value.$error : null"/>
          <b-form-invalid-feedback id="formationIntervalValidation">
            Значение должно быть целым числом не менее 5
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group label="Количество формируемых рекомендаций">
          <b-form-input v-model="$v.formation.size.value.$model" :readonly="!isAdmin"
                        required
                        aria-describedby="formationSizeValidation"
                        :state="$v.formation.size.value.$dirty ? !$v.formation.size.value.$error : null"/>
          <b-form-invalid-feedback id="formationSizeValidation">
            Значение должно быть целым числом от 1 до 30
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group label="Интервал (в минутах) пересчёта популярных товаров, которые отображаются для пользователей, для которых нет персональных рекомендаций">
          <b-form-input v-model="$v.formation.popular.interval.value.$model" :readonly="!isAdmin"
                        required
                        aria-describedby="formationPopularValidation"
                        :state="$v.formation.popular.interval.value.$dirty ? !$v.formation.popular.interval.value.$error : null"/>
          <b-form-invalid-feedback id="formationPopularValidation">
            Значение должно быть целым числом не менее 30
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group label="Расписание запуска переобучения модели машинного обучения">
          <vue-cron-editor-buefy v-model="formation.retraining.value" locale="ru" :custom-locales="cronPluginLocalization"/>
          {{ formation.retraining.value }}
        </b-form-group>
        <b-form-group label="Максимальная доля предопределённых товаров в рекомендациях">
          <b-form-input v-model="$v.formation.recommendations.ratio.value.$model" :readonly="!isAdmin"
                        required
                        aria-describedby="formationRecommendationsRatioValidation"
                        :state="$v.formation.recommendations.ratio.value.$dirty ? !$v.formation.recommendations.ratio.value.$error : null"/>
          <b-form-invalid-feedback id="formationRecommendationsRatioValidation">
            Значение должно быть вещественным числом от 0 до 1
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group label="Количество потоков для обучения модели">
          <b-form-input v-model="$v.training.threads.number.value.$model" :readonly="!isAdmin"
                        required
                        aria-describedby="trainingThreadsNumberValidation"
                        :state="$v.training.threads.number.value.$dirty ? !$v.training.threads.number.value.$error : null"/>
          <b-form-invalid-feedback id="trainingThreadsNumberValidation">
            Значение должно быть натуральным числом
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group label="Количество эпох обучения модели">
          <b-form-input v-model="$v.training.epochs.count.value.$model" :readonly="!isAdmin"
                        required
                        aria-describedby="trainingEpochsCountValidation"
                        :state="$v.training.epochs.count.value.$dirty ? !$v.training.epochs.count.value.$error : null"/>
          <b-form-invalid-feedback id="trainingEpochsCountValidation">
            Значение должно быть натуральным числом
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group label="Размерность эмбеддингов признаков товаров">
          <b-form-input v-model="$v.training.components.count.value.$model" :readonly="!isAdmin"
                        required
                        aria-describedby="trainingComponentsCountValidation"
                        :state="$v.training.components.count.value.$dirty ? !$v.training.components.count.value.$error : null"/>
          <b-form-invalid-feedback id="trainingComponentsCountValidation">
            Значение должно быть натуральным числом
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group label="Коэффициент скорости обучения">
          <b-form-input v-model="$v.training.learning.rate.value.$model" :readonly="!isAdmin"
                        required
                        aria-describedby="trainingLearningRateValidation"
                        :state="$v.training.learning.rate.value.$dirty ? !$v.training.learning.rate.value.$error : null"/>
          <b-form-invalid-feedback id="trainingLearningRateValidation">
            Значение должно быть вещественным числом в полуинтервале (0; 1]
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group label="Штраф L2-регуляризации">
          <b-form-input v-model="$v.training.item.alpha.value.$model" :readonly="!isAdmin"
                        required
                        aria-describedby="trainingItemAlphaValidation"
                        :state="$v.training.item.alpha.value.$dirty ? !$v.training.item.alpha.value.$error : null"/>
          <b-form-invalid-feedback id="trainingItemAlphaValidation">
            Значение должно быть вещественным числом в полуинтервале (0; 1]
          </b-form-invalid-feedback>
        </b-form-group>
        <b-button type="submit" variant="primary" class="mt-3 mx-auto form-control" :disabled="submitting || !isAdmin">
          <loading-spinner style="margin-top: -4px;" v-if="submitting"/>
          <div v-else>
            Сохранить
          </div>
        </b-button>
      </b-form>
    </div>
  </b-container>
</template>

<script>
import {mapGetters} from "vuex";
import {required} from "vuelidate/lib/validators";
import LoadingSpinner from "@/components/LoadingSpinner.vue";
import configServer from "@/modules/config-server";
import validations from "@/modules/validation-utils";
import VueCronEditorBuefy from 'vue-cron-editor-buefy';

export default {
  name: "grade-formation",
  components: {
    'loading-spinner': LoadingSpinner,
    'vue-cron-editor-buefy': VueCronEditorBuefy
  },
  data() {
    return {
      submitting: false,
      loading: false,
      rating: {
        min: {
          serverName: 'rating.value.min',
          value: null
        },
        max: {
          serverName: 'rating.value.max',
          value: null
        },
        function: {
          coefficient: {
            serverName: 'rating.function.coefficient',
            value: null
          },
          timeSpent: {
            degreeMultiplier: {
              serverName: 'rating.time.spent.function.multiplier',
              value: null
            },
            max: {
              serverName: 'rating.time.spent.function.max',
              value: null
            }
          }
        },
        noInteraction: {
          time: {
            serverName: 'rating.no-interaction.time',
            value: null,
          },
          ratingSubtraction: {
            serverName: 'rating.no-interaction.rating-subtraction',
            value: null
          }
        }
      },
      priceLevel: {
        medium: {
          serverName: 'price.level.medium',
          value: null
        },
        high: {
          serverName: 'price.level.high',
          value: null
        }
      },
      formation: {
        interval: {
          serverName: 'formation.interval',
          value: null
        },
        size: {
          serverName: 'formation.size',
          value: null
        },
        popular: {
          interval: {
            serverName: 'formation.popular.interval',
            value: null,
          }
        },
        retraining: {
          serverName: 'formation.retraining.cron',
          value: null
        },
        recommendations: {
          ratio: {
            serverName: 'formation.recommendations.ratio',
            value: null
          }
        }
      },
      training: {
        threads: {
          number: {
            serverName: 'training.threads.number',
            value: null
          }
        },
        epochs: {
          count: {
            serverName: 'training.epochs.count',
            value: null
          }
        },
        components: {
          count: {
            serverName: 'training.components.count',
            value: null
          }
        },
        learning: {
          rate: {
            serverName: 'training.learning.rate',
            value: null
          }
        },
        item: {
          alpha: {
            serverName: 'training.item.alpha',
            value: null
          }
        }
      },
      cronPluginLocalization: {
          ru: {
              every: "Каждый",
              mminutes: "минут(ы)",
              hoursOnMinute: "час(а) в минуту",
              daysAt: "день в",
              at: "в",
              onThe: "в",
              dayOfEvery: "день, каждые",
              monthsAt: "месяц(ев), в",
              everyDay: "Каждый",
              mon: "Пн.",
              tue: "Вт.",
              wed: "Ср.",
              thu: "Чт.",
              fri: "Пт.",
              sat: "Сб.",
              sun: "Вс.",
              hasToBeBetween: "Должен быть между",
              and: "и",
              minutes: "Минуты",
              hourly: "Часы",
              daily: "Дни",
              weekly: "Недели",
              monthly: "Месяцы",
              advanced: "Дополнительно",
              cronExpression: "Cron-выражение:"
          }
      }
    };
  },
  validations() {
    return {
      rating: {
        min: {
          value: {
            required,
            positiveAndSmallerThanMaxValue: (value) => validations.isFloat(value) && value >= 0 && (this.rating.max.value == null || value < this.rating.max.value)
          }
        },
        max: {
          value: {
            required,
            positiveAndBiggerThanMinValue: (value) => validations.isFloat(value) && value > 0 && (this.rating.min.value == null || value > this.rating.min.value)
          }
        },
        function: {
          coefficient: {
            value: {
              required,
              isFloat: (value) => validations.isFloat(value)
            }
          },
          timeSpent: {
            degreeMultiplier: {
              value: {
                required,
                isFloat: (value) => validations.isFloat(value)
              }
            },
            max: {
              value: {
                required,
                isFloat: (value) => validations.isFloat(value)
              }
            }
          }
        },
        noInteraction: {
          time: {
            value: {
              required,
              positive: (value) => validations.isInt(value) && value > 0
            }
          },
          ratingSubtraction: {
            value: {
              required,
              inRatingBounds: (value) => validations.isFloat(value) && value > 0 && value < 100
            }
          }
        }
      },
      priceLevel: {
        medium: {
          value: {
            required,
            positiveAndSmallerThanHigh: (value) => validations.isFloat(value) && value > 0 && (this.priceLevel.high.value == null || value < this.priceLevel.high.value)
          }
        },
        high: {
          value: {
            required,
            positiveAndBiggerThanMedium: (value) => validations.isFloat(value) && value > 0 && (this.priceLevel.medium.value == null || value > this.priceLevel.medium.value)
          }
        }
      },
      formation: {
        interval: {
          value: {
            required,
            notLessThanFive: (value) => validations.isInt(value) && (value >= 5 || Number.parseInt(value) === -1)
          }
        },
        size: {
          value: {
            required,
            positiveAndLessThan30: (value) => validations.isInt(value) && value > 0 && value <= 30
          }
        },
        popular: {
          interval: {
            value: {
              required,
              notLessThanThirty: (value) => validations.isInt(value) && value >= 30
            }
          }
        },
        recommendations: {
          ratio: {
            value: {
              required,
              isPossibility: (value) => validations.isFloat(value) && value >= 0 && value <= 1
            }
          }
        }
      },
      training: {
        threads: {
          number: {
            value: {
              required,
              positive: (value) => validations.isInt(value) && value > 0
            }
          }
        },
        epochs: {
          count: {
            value: {
              required,
              positive: (value) => validations.isInt(value) && value > 0
            }
          }
        },
        components: {
          count: {
            value: {
              required,
              positive: (value) => validations.isInt(value) && value > 0
            }
          }
        },
        learning: {
          rate: {
            value: {
              required,
              isPossibility: (value) => validations.isFloat(value) && value > 0 && value <= 1
            }
          }
        },
        item: {
          alpha: {
            value: {
              required,
              isPossibility: (value) => validations.isFloat(value) && value > 0 && value <= 1
            }
          }
        }
      }
    }
  },
  created() {
    this.fetchParams();
    document.title = this.$route.meta.title;
  },
  computed: {
    ...mapGetters([
      "isRolesEmpty",
      "isAdmin"
    ])
  },
  methods: {
    fetchParams() {
      this.loading = true;
      configServer.getConfig()
          .then(response => {
            const params = response.data.propertySources?.find(source => source.name === "redis:recommendation").source;
            if (params != null) {
              this.rating.max.value = params[this.rating.max.serverName];
              this.rating.min.value = params[this.rating.min.serverName];
              this.rating.function.coefficient.value = params[this.rating.function.coefficient.serverName];
              this.rating.function.timeSpent.max.value = params[this.rating.function.timeSpent.max.serverName];
              this.rating.function.timeSpent.degreeMultiplier.value = params[this.rating.function.timeSpent.degreeMultiplier.serverName];
              this.priceLevel.medium.value = params[this.priceLevel.medium.serverName];
              this.priceLevel.high.value = params[this.priceLevel.high.serverName];
              this.rating.noInteraction.time.value = params[this.rating.noInteraction.time.serverName];
              this.rating.noInteraction.ratingSubtraction.value = params[this.rating.noInteraction.ratingSubtraction.serverName];
              this.formation.interval.value = params[this.formation.interval.serverName];
              this.formation.size.value = params[this.formation.size.serverName];
              this.formation.popular.interval.value = params[this.formation.popular.interval.serverName];
              this.formation.retraining.value = params[this.formation.retraining.serverName];
              this.formation.recommendations.ratio.value = params[this.formation.recommendations.ratio.serverName];
              this.training.threads.number.value = params[this.training.threads.number.serverName];
              this.training.epochs.count.value = params[this.training.epochs.count.serverName];
              this.training.components.count.value = params[this.training.components.count.serverName];
              this.training.learning.rate.value = params[this.training.learning.rate.serverName];
              this.training.item.alpha.value = params[this.training.item.alpha.serverName];
            }
          })
          .catch(error => {
            if (error.response != null) {
              if (error.response.status === 403) {
                this.errorMessage = "Недостаточно прав для просмотра параметров";
              } else if (error.response.status === 429) {
                this.errorMessage = "Слишком много запросов";
              } else if (error.response.status === 401) {
                this.errorMessage = "Сессия истекла. Пожалуйста, перезайдите в аккаунт";
              } else {
                this.errorMessage = "Не удалось загрузить параметры";
              }
            } else {
              this.errorMessage = "Не удалось загрузить параметры";
            }
            this.$bvToast.toast(this.errorMessage + (error.response == null ? '' : ` (код ${error.response.status})`), {
              title: "Ошибка",
              variant: "danger",
              autoHideDelay: 5000,
              appendToast: true
            });
          })
          .finally(() => this.loading = false);
    },
    onSubmit() {
      this.$v.$touch();
      if (this.submitting || this.$v.$anyError) {
        window.scrollTo({top: 0, behavior: 'smooth'});
        return;
      }

      this.submitting = true;
      configServer.updateConfig({
        [this.rating.min.serverName]: this.rating.min.value,
        [this.rating.max.serverName]: this.rating.max.value,
        [this.rating.function.coefficient.serverName]: this.rating.function.coefficient.value,
        [this.rating.function.timeSpent.degreeMultiplier.serverName]: this.rating.function.timeSpent.degreeMultiplier.value,
        [this.rating.function.timeSpent.max.serverName]: this.rating.function.timeSpent.max.value,
        [this.priceLevel.medium.serverName]: this.priceLevel.medium.value,
        [this.priceLevel.high.serverName]: this.priceLevel.high.value,
        [this.rating.noInteraction.time.serverName]: this.rating.noInteraction.time.value,
        [this.rating.noInteraction.ratingSubtraction.serverName]: this.rating.noInteraction.ratingSubtraction.value,
        [this.formation.interval.serverName]: this.formation.interval.value,
        [this.formation.size.serverName]: this.formation.size.value,
        [this.formation.popular.interval.serverName]: this.formation.popular.interval.value,
        [this.formation.retraining.serverName]: this.formation.retraining.value,
        [this.formation.recommendations.ratio.serverName]: this.formation.recommendations.ratio.value,
        [this.training.threads.number.serverName]: this.training.threads.number.value,
        [this.training.epochs.count.serverName]: this.training.epochs.count.value,
        [this.training.components.count.serverName]: this.training.components.count.value,
        [this.training.learning.rate.serverName]: this.training.learning.rate.value,
        [this.training.item.alpha.serverName]: this.training.item.alpha.value
      }).then(() => {
        this.$bvToast.toast('Параметры успешно сохранены', {
          variant: 'success',
          solid: true,
          noCloseButton: true
        });
      }).catch(error => {
        if (error.response != null) {
          if (error.response.status === 403) {
            this.errorMessage = "Недостаточно прав для изменения параметров";
          } else if (error.response.status === 429) {
            this.errorMessage = "Слишком много запросов";
          } else if (error.response.status === 401) {
            this.errorMessage = "Сессия истекла. Пожалуйста, перезайдите в аккаунт";
          } else {
            this.errorMessage = "Не удалось сохранить параметры";
          }
        } else {
          this.errorMessage = "Не удалось сохранить параметры";
        }
        this.$bvToast.toast(this.errorMessage + (error.response == null ? '' : ` (код ${error.response.status})`), {
          title: "Ошибка",
          variant: "danger",
          autoHideDelay: 5000,
          appendToast: true
        });
      }).finally(() => this.submitting = false);
    }
  }
}
</script>