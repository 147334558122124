import axios from 'axios';
import store from '@/store';
import router from "@/router";

const AUTH_AXIOS = axios.create({
    baseURL: '/api/auth',
    timeout: 300000
});

const CONFIG_AXIOS = axios.create({
    baseURL: '/api/config-server',
    timeout: 300000
});

const USER_INTERACTION_AXIOS = axios.create({
    baseURL: '/api/user-interaction',
    timeout: 300000
});

const PRODUCT_RECOMMENDATION_AXIOS = axios.create({
    baseURL: '/api/product-recommendation',
    timeout: 300000
});

const CATALOG_SERVER_AXIOS = axios.create({
    baseURL: '/api/catalog-server',
    timeout: 300000
});


function addUnauthorizedInterceptor(axiosInstance) {
    axiosInstance.interceptors.response.use(response => response, error => {
        const status = error.response ? error.response.status : null;
        if (status === 401) {
            return store.dispatch("refreshToken")
                .then(_ => {
                    error.config.baseURL = undefined;
                    return CONFIG_AXIOS.request(error.config);
                })
                .catch(err => {
                    return router.push('/login')
                        .then(() => {
                            return Promise.reject(err);
                        });
                });
        }
        return Promise.reject(error);
    });
}

addUnauthorizedInterceptor(CONFIG_AXIOS);
addUnauthorizedInterceptor(USER_INTERACTION_AXIOS);
addUnauthorizedInterceptor(PRODUCT_RECOMMENDATION_AXIOS);
addUnauthorizedInterceptor(CATALOG_SERVER_AXIOS);

export {CONFIG_AXIOS, AUTH_AXIOS, USER_INTERACTION_AXIOS, PRODUCT_RECOMMENDATION_AXIOS, CATALOG_SERVER_AXIOS};
