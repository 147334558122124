import {CATALOG_SERVER_AXIOS} from "@/modules/backend-config";

export default {
    getProductsByCodes(codes) {
        return CATALOG_SERVER_AXIOS.post('/product/listProductCodes', codes, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
    },
    searchForProducts(query, pageNumber, pageSize, sortName, sortDirection) {
        return CATALOG_SERVER_AXIOS.get('/product/query', {
            params: {
                'query': query,
                'page': pageNumber,
                'page_size': pageSize,
                'sort_name': sortName,
                'sort_direction': sortDirection
            }
        })
    }
}