<template>
  <div>
    <h4 class="text-center">Создание события</h4>
    <b-form @submit.stop.prevent="onSubmit">
      <b-form-group
          label="Название события (начинается с латинской буквы, далее может включать цифры, знаки подчёркивания и латинские буквы)">
        <b-form-input v-model="name" required @keypress="isEventName($event)" :formatter="formatUpperCase"/>
      </b-form-group>
      <b-form-group label="Описание события">
        <b-form-input v-model="$v.description.$model"
                      :state="$v.description.$dirty ? !$v.description.$error : null"/>
      </b-form-group>
      <b-form-group label="Вес события">
        <b-form-input type="number" v-model.number="$v.value.$model" required
                      :state="$v.value.$dirty ? !$v.value.$error : null"/>
      </b-form-group>
      <b-form-checkbox v-model="$v.isAbsoluteValue.$model" required>
        Абсолютное значение
      </b-form-checkbox>
      <b-form-checkbox v-model="$v.isStoppingRecommendation.$model" required>
        Останавливает рекомендацию
      </b-form-checkbox>
      <div class="text-right mt-3">
        <b-button class="mr-2" variant="secondary" @click="onCancel">
          Отмена
        </b-button>
        <b-button variant="primary" type="submit">
          Создать
        </b-button>
      </div>
    </b-form>
  </div>
</template>

<script>
import {required} from "vuelidate/lib/validators";

export default {
  name: "event-creation",
  data() {
    return {
      name: '',
      description: null,
      value: null,
      isStoppingRecommendation: false,
      isAbsoluteValue: false
    };
  },
  validations() {
    return {
      description: {},
      value: {
        required
      },
      isStoppingRecommendation: {
        required
      },
      isAbsoluteValue: {
        required
      }
    }
  },
  methods: {
    isEventName(event) {
      if (/^[a-zA-Z]+\w*$/.test(this.name + String.fromCharCode(event.keyCode))) {
        return true;
      } else {
        event.preventDefault();
      }
    },
    formatUpperCase(input) {
      return input.toUpperCase();
    },
    onSubmit() {
      this.$emit('submit', this.$data);
    },
    onCancel() {
      this.$emit('cancel');
    }
  }
}
</script>